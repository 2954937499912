import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js"
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import './LiveTv.css'
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import Features from "components/features/ThreeColSimple.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-color: red;
`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      
      <MainFeature1
        subheading={<Subheading>Vizual TV</Subheading>}
        heading="Vizual TV is Content Based Channel with Advanced Marketing Strategy."
        description={<Description>You can watch Vizual TV on any devices (Desktop/Tab/Phone) and Roku/Firestick Platforms. The Channel helps to Better Targeting, Reliable ROI with Brand Promotions.</Description>}
        buttonRounded={false}
        primaryButtonText="More Videos"
        primaryButtonUrl="https://vimeo.com/showcase/9206087"
        video={true}
        // imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <Footer />
    </AnimationRevealPage>
  );
};
