import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import FAQ from "components/faqs/SingleCol.js";
import Features from "components/features/DashedBorderSixFeatures";
import Experties from "components/features/TwoColWithButton";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Experties />
      <Features />
      {/* <Pricing /> */}
      <Testimonial
        heading="Our Paying Customers"
      />
      {/* <FAQ /> */}
      <Footer/>
    </AnimationRevealPage>
  );
};
